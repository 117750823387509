import React from 'react';
import { Seo } from '../components/layout/seo';
import Service from '../components/services/service';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

class Massage extends React.Component {
  render() {
    const data = get(this, 'props.data.contentfulService');
    const Img1 = getImage(data.squareImg.gatsbyImageData);
    const Img2 = getImage(data.portraitImage.gatsbyImageData);
    const List1 = data.list1.list1;
    const List2 = data.list2.list2;
    const column1Section1 = data.column1Section1;
    const column1Section1Values = data.column1Section1Values.internal.content;
    const column2Section1 = data.column2Section1;
    const column2Section1Values = data.column2Section1Values.internal.content;
    return (
      <Service
        title={data.name}
        position="Massage Therapists"
        caption={data.caption}
        info={data.description.childMarkdownRemark.html}
        secondary={data.secondary}
        secondaryInfo={data.secondaryInfo.childMarkdownRemark.html}
        imgSquare={Img1}
        imgPortrait={Img2}
        list1type={data.list1type}
        list2type={data.list2type}
        list2display={data.list2display}
        list1={List1}
        list1title={data.list1title}
        list2={List2}
        list2title={data.list2title}
        col1sec1title={column1Section1}
        col1sec1={column1Section1Values}
        col1sec2title
        col1sec2
        col2sec1title={column2Section1}
        col2sec1={column2Section1Values}
        col2sec2title
        col2sec2
        col3sec1title
        col3sec1
        offeredBy={data.offeredBy}
      />
    );
  }
}

export const Head = ({ location, data }) => {
  const description =
    data.contentfulService.description.childMarkdownRemark.html
      .replace('<p>', '')
      .replace('</p>', '');
  return (
    <Seo
      title={data.contentfulService.name}
      pathname={location.pathname}
      description={description}
    />
  );
};
export default Massage;

export const pageQuery = graphql`
  query {
    contentfulService(name: { eq: "Registered Massage Therapy" }) {
      name
      caption
      description {
        childMarkdownRemark {
          html
        }
      }
      list1title
      list1 {
        list1
      }
      list1type
      list2type
      list2display
      list2title
      list2 {
        list2
      }
      secondary
      secondaryInfo {
        childMarkdownRemark {
          html
        }
      }
      portraitImage {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      squareImg {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
      column1Section1
      column1Section1Values {
        internal {
          content
        }
      }
      column1Section2
      column1Section2Values {
        internal {
          content
        }
      }
      column2Section1
      column2Section1Values {
        internal {
          content
        }
      }
      column2Section2
      column2Section2Values {
        internal {
          content
        }
      }
      column3Section1
      column3Section1Values {
        internal {
          content
        }
      }
      offeredBy {
        name
        title
        image {
          description
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
